import React from 'react';
import styles from './SectionTitle.module.css';
import PropTypes from 'prop-types';

const SectionTitle = (props) => {

	return (
		<div className={styles.sectionTitle}>
			<div className={styles.border}/>
			<div className={styles.title}>{props.title}</div>
		</div>
	);

};

SectionTitle.propTypes = {
	title: PropTypes.string.isRequired
};

export default SectionTitle;