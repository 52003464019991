import React from 'react';
import './App.css';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import IndexPage from "../Pages/IndexPage/IndexPage";

const App = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path={"/"} component={IndexPage}/>
			</Switch>
		</BrowserRouter>
	);
};

export default App;
