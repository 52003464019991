import React from 'react';
import styles from './BellyMenu.module.css';
import PropTypes from 'prop-types';

const BellyMenu = (props) => {

	let items = ["Home", "Services", "Gallery", "Contact"];

	return (
		<div className={styles.bellyMenu}>
			{
				items.map((it, index) => {
					let selected = it === props.page ? [styles.item, styles.selected].join(" ") : styles.item;
					return <div onClick={() => props.modal(true)} className={selected} key={index}>{it}</div>
				})
			}

		</div>
	);

};

BellyMenu.propTypes = {
	page: PropTypes.string.isRequired
};

export default BellyMenu;