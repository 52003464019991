import React from 'react';
import styles from './Header.module.css';

const Header = (props) => {

	let desc = <p className={styles.description}>The Central Coast's premiere location for glass restoration, chip repair and cutting.
	</p>

	return (
		<div className={styles.header}>
			<div className={styles.logo}><span className={styles.bold}>Glass Studios</span> LTD.</div>
			<div className={styles.belly}>
				<div className={styles.spacer}>
					<div className={styles.bar}/>
					<div className={styles.holder}>
						<button onClick={() => props.modal(true)} className={styles.contact}>Contact Us</button>
						<button onClick={() => props.modal(true)} className={styles.services}>Our Services</button>
					</div>
				</div>
				<div className={styles.list}>
					<div className={[styles.item, styles.first].join(" ")}>Cutting</div>
					<div className={styles.item}>Repair</div>
					<div className={[styles.item, styles.last].join(" ")}>& More</div>
				</div>
			</div>


		</div>
	);

};

export default Header;