import React from 'react';
import styles from './PhotoSlider.module.css';
import SectionTitle from "../SectionTitle/SectionTitle";
import PropTypes from 'prop-types';

class PhotoSlider extends React.Component {

	render() {
		return (
			<div className={styles.photoSlider}>
				<SectionTitle title={"Our Work"}/>
				{
					this.props.images.map((im, index) => {
						return <img src={im} alt="" key={index} onClick={() => this.props.showImage(true, index)}/>
					})
				}
				<div className="clear"/>
				<button onClick={() => this.props.modal(true)}>View Gallery <i className="fad fa-chevron-double-right"/></button>
				<div className="clear"/>
			</div>
		);
	}

}

PhotoSlider.propTypes = {
	images: PropTypes.array.isRequired
};

export default PhotoSlider;