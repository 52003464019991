import React from 'react';
import styles from './Modal.module.css';
import PropTypes from 'prop-types';

class Modal extends React.Component {

	render() {

		let mode = this.props.open ? [styles.modal, styles.open].join(" ") : styles.modal;

		return (
			<div className={mode}>
				<div className={styles.background} onClick={() => this.props.close(false)}/>
				<div className={styles.body} style={{top: `${window.pageYOffset + 50}px`}}>
					<div className={styles.close} onClick={() => this.props.close(false)}><i className="fas fa-times"/></div>
					{this.props.children}
				</div>
			</div>
		);
	}

}

Modal.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired
};

export default Modal;