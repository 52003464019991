import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {

	return (
		<div className={styles.footer}>
			<div className={styles.title}>Glass Studios LTD.</div>
			<div className={styles.phone}>(805) 736 - 1729</div>
			<div className={styles.address}>502 B North 8th Street | Lompoc, CA | 93436</div>
			<div className={styles.copy}>&copy; {new Date().getFullYear()} Glass Studios LTD. All Rights Reserved.</div>
		</div>
	);

};

export default Footer;