import React from 'react';
import styles from './IndexPage.module.css';
import BackgroundImage from '../../../static/images/vase.png';
import BellyMenu from "../../Inflatables/BellyMenu/BellyMenu";
import Header from "../../Inflatables/Header/Header";
import Footer from "../../Inflatables/Footer/Footer";
import PhotoSlider from "../../Inflatables/PhotoSlider/PhotoSlider";
import VerticalSpacer from "../../Inflatables/VerticalSpacer/VerticalSpacer";
import Modal from "../../Inflatables/Modal/Modal";
import vase1 from '../../../static/images/vase1.jpg';
import vase2 from '../../../static/images/vase2.jpg';
import vase3 from '../../../static/images/vase3.jpg';
import vase4 from '../../../static/images/vase4.jpg';
import vase5 from '../../../static/images/vase5.png';
import vase6 from '../../../static/images/vase6.jpg';

class IndexPage extends React.Component {

	state = {
		underConstructionModal: false,
		photoModal: false,
		selectedImage: -1
	};

	photoGridImages = [vase1, vase2, vase3, vase4, vase5, vase6];

	setConstructionModal = (mode) => {
		let s = {...this.state};
		s.underConstructionModal = mode;
		this.setState(s);
	};

	setPhotoModal = (mode, imageIndex) => {
		let s = {...this.state};
		s.photoModal = mode;
		s.selectedImage = imageIndex;
		this.setState(s);
	};

	incrementPhoto = (num) => {
		let s = {...this.state};
		let newNum = s.selectedImage + num;
		if (newNum > this.photoGridImages.length - 1) newNum = 0;
		if (newNum < 0) newNum = this.photoGridImages.length - 1;
		s.selectedImage = newNum;
		this.setState(s);
	};

	render() {
		return (
			<div className={styles.indexPage}>
				<Modal open={this.state.underConstructionModal} close={this.setConstructionModal}>
					<div className={styles.icon}><i className="fad fa-tools"/></div>
					<div className={styles.title}>Please bear with us during construction.</div>
					<div className={styles.subtitle}>We are still assisting customers during our website construction. Please feel free to contact
						us
						with any questions you may have.
					</div>
					<div className={styles.phone}>(805) 736 - 1729</div>
					<div className={styles.address}>502 B North 8th Street | Lompoc, CA | 93436</div>
				</Modal>
				<Modal open={this.state.photoModal} close={this.setPhotoModal}>
					<div className={styles.left} onClick={() => this.incrementPhoto(-1)}>
						<i className="fad fa-chevron-double-left"/>
					</div>
					{
						this.state.selectedImage >= 0
							? <img className={styles.modalImage} src={this.photoGridImages[this.state.selectedImage]} alt=""/>
							: null
					}
					<div className={styles.right} onClick={() => this.incrementPhoto(1)}>
						<i className="fad fa-chevron-double-right"/>
					</div>
				</Modal>
				<img className={styles.vase} src={BackgroundImage} alt=""/>
				<div className={styles.workSpace}>
					<BellyMenu page={"Home"} modal={this.setConstructionModal}/>
					<VerticalSpacer height={200}/>
					<Header modal={this.setConstructionModal}/>
					<VerticalSpacer height={100}/>
					<PhotoSlider modal={this.setConstructionModal} images={this.photoGridImages} showImage={this.setPhotoModal}/>
					<VerticalSpacer height={150}/>
					<Footer/>
				</div>
				<div className="clear"/>
			</div>
		);
	}

}

export default IndexPage;